<template>
  <div class="out-wrap">
    <div class="wrap">
      <div class="top-img"></div>
      <img src="../../assets/img/bk.png" class="bk-img" />
      <div class="top-text">
        <div class="top-title1">浦发银行对公开户服务</div>
        <div class="top-title2">便捷、高效，7*24小时预约申请</div>
      </div>
      <div class="bottom-btn">
        <div class="btn1">
          <el-button
            class="to-open-account"
            @click="
              () => {
                $router.push('/form');
              }
            "
            >开户申请<img class="arrow-icon" src="../../assets/img/arrow.png"
          /></el-button>
        </div>
        <div class="btn2">
          <el-button @click="toQuery" class="query-open-account">开户进度查询</el-button>
        </div>
        <div class="footer-text">
          <p>copyright© 上海浦东发展银行</p>
          <p>服务热线：95528</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    toQuery(){
      window.open("https://wap.spdb.com.cn/ewap/NewWechatUpdateOpenAcct.action")
    }
  }
};
</script>

<style lang="less" scoped>
.out-wrap {
  position: relative;
  max-width: 480px;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
}
.wrap {
  position: relative;
  width: 100%;
  height: 100vh;
  .top-img {
    position: relative;
    top: -2px;
    margin: auto;
    width: 8.36rem;
    height: 1.2rem;
    background: url("../../assets/img/top.png") no-repeat;
    background-size: 100%;
    z-index: 2;
  }
  .bk-img {
    position: absolute;
    width: 100%;
    height: 104vh;
    top: -20px;
  }
  .top-text {
    position: absolute;
    z-index: 3;
    width: 100%;
    .top-title1 {
      text-align: center;
      font-size: 0.8rem;
      color: #fff;
      line-height: 1.5rem;
      margin-top: 0.5rem;
    }
    .top-title2 {
      text-align: center;
      font-size: 0.33rem;
      letter-spacing: 1px;
      color: #bdc9fc;
    }
  }
  .bottom-btn {
    position: absolute;
    bottom: 0.96rem;
    width: 100%;
    text-align: center;
    .btn1 {
      /deep/ .el-button {
        width: 80%;
        height: 1.3rem;
        background: linear-gradient(90deg, #63b1ff, #6581fc);
        color: #fff;
        font-size: 18px;
      }
      .arrow-icon {
        width: 0.4rem;
        height: 0.4rem;
        vertical-align: middle;
        margin-left: 0.1rem;
      }
    }
    .btn2 {
      margin: 0.45rem 0;
      /deep/ .el-button {
        width: 80%;
        height: 1.3rem;
        font-size: 18px;
      }
    }
    .footer-text {
      color: #a8a8a8;
    }
  }
}
</style>